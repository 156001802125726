<!--
 * @Author: mjzhu
 * @Date: 2022-06-09 10:11:22
 * @LastEditTime: 2024-01-28 17:27:18
 * @FilePath: \awx-ui\src\pages\securityCenter\user.vue
-->

<template>
  <div class="user-list">
    <a-row type="flex" align="middle">
      <a-col :span="8">
        <a-button @click="onSearch">刷新</a-button>
        <a-button v-if="isSuperuser" style="margin-left: 10px" type="primary" @click="createUser({})"><svg-icon class="btn-before-icon mgr6" icon-class="file-add"></svg-icon>添加用户</a-button>
      </a-col>
      <a-col :span="16" style="text-align: right">
        <a-input-search placeholder="请输入关键词查询" class="w470" @change="(value) => getVal(value, 'username')" allowClear @search="onSearch" />
      </a-col>
    </a-row>
    <div class="table-info">
      <a-table class="table-15 opt-table" @change="tableChange" :columns="columns" :loading="loading" :dataSource="dataSource" rowKey="id" :customRow="handleClickRow" :pagination="pagination"></a-table>
    </div>
  </div>
</template>

<script>
import AddUser from "./components/addUser.vue";
import moment from "moment";
import DelectUser from "./components/delectUser.vue";

export default {
  name: "USER",
  data() {
    const self = this
    return {
      params: {},
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
        showTotal: (total) => `共 ${total} 条`,
      },
      setSelectedIndex: -1,
      dataSource: [],
      loading: false,
      columns: [
        {
          title: "序号",
          key: "index",
          width: 70,
          customRender: (text, row, index) => {
            return (
              <span>
                {parseInt(
                  this.pagination.current === 1
                    ? index + 1
                    : index +
                        1 +
                        this.pagination.pageSize * (this.pagination.current - 1)
                )}
              </span>
            );
          },
        },
        { title: "用户名", key: "username", dataIndex: "username" },
        // {
        //   title: "角色",
        //   key: "createUser",
        //   dataIndex: "createUser",
        // },
        {
          title: "邮箱",
          key: "email",
          dataIndex: "email",
        },
        { title: "电话", key: "phone", dataIndex: "phone" },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
          customRender: (text) => {
            return <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>;
          },
        },
        {
          title: "操作",
          key: "action",
          width: 160,
          customRender: (text, row, index) => {
            return row.userType !== 1 ? (
              <span class="flex-container">
                <a class="btn-opt" onClick={(e) => this.createUser(row, e)}>
                  编辑
                </a>
                <a-divider type="vertical" />
                <a class="btn-opt" onClick={(e) => this.delectUser(row, e)}>
                  删除
                </a>
                {
                  (self.isSuperuser && self.notCurrentUser(row)) && (
                    <span onClick={(e) => e.stopPropagation()}>
                      <a-divider type="vertical" />
                      { row.state === 'disabled' && <a class="btn-opt" onClick={(e) => this.enable(row, e)}> 
                          {row.state === 'disabled' ? '启用' : '禁用'}
                        </a>
                      }
                    {
                      row.state !== 'disabled' && 
                      <a-popconfirm
                        title="确定禁用吗"
                        ok-text="确定"
                        placement="left"
                        cancel-text="取消"
                        overlayClassName="master-disable"
                        onConfirm={(e) => this.enable(row, e)}
                      >
                      <a class="btn-opt" > 
                        {row.state === 'disabled' ? '启用' : '禁用'}
                      </a>
                    </a-popconfirm>
                    }
                    </span>
                  )
                }
              </span>
            ) : (
              <span class="flex-container">
                <a class="btn-opt" style="color: #bbb">
                  编辑
                </a>
                <a-divider type="vertical" />
                <a class="btn-opt" style="color: #bbb">
                  删除
                </a>
              </span>
            );
          },
        },
      ],
      isSuperuser: false,
    };
  },
  computed: {},
  methods: {
    handleClickRow(record, index) {
      return {
        style: {
          "background-color":
            index === this.setSelectedIndex ? "#e4eefd " : "#fff",
        },
        on: {
          click: () => {
            this.setSelectedIndex = index;
            this.createUser(record);
          },
        },
      };
    },
    clearSelectIndex() {
      this.setSelectedIndex = -1;
    },
    tableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getUserList();
    },
    getVal(val, filed) {
      this.params[`${filed}`] = val.target.value;
    },
    enable (row, e) {
      if (e) e.stopPropagation()
      const ajaxApi = row.state === 'disabled'  ? global.API.updateUser + `/${row.id}/enable` : global.API.updateUser + `/${row.id}/disable`
      this.$axiosPut(ajaxApi, {})
      .then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          const str = row.state  === 'disabled' ? '启用成功' : '禁用成功'
          this.$message.success(str, 2);
          this.onSearch();
        }
      })
      .catch((err) => {});
    },
    notCurrentUser (row) {
      return JSON.parse(
        localStorage.getItem("admin.user")
      ).id !== row.id
    },
    //   查询
    onSearch() {
      this.pagination.current = 1;
      this.getUserList();
    },
    createUser(obj, e) {
      if (e) e.stopPropagation();
      const self = this;
      let width = 520;
      let title = JSON.stringify(obj) === "{}" ? "添加用户" : "编辑用户";
      let content = (
        <AddUser
          detail={obj}
          clearSelectIndex={() => self.clearSelectIndex()}
          callBack={() => self.getUserList()}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        onCancel: () => {
          self.clearSelectIndex();
        },
        icon: () => {
          return <div />;
        },
      });
    },
    delectUser(obj, e) {
      if (e) e.stopPropagation();
      const self = this;
      let width = 400;
      let content = (
        <DelectUser
          sysTypeTxt="用户"
          detail={obj}
          callBack={() => self.getUserList()}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              <a-icon
                type="question-circle"
                style="color:#2F7FD1 !important;margin-right:10px"
              />
              提示
            </div>
          );
        },
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    getUserList() {
      this.loading = true;
      const params = {
        page_size: this.pagination.pageSize,
        page: this.pagination.current,
        keyword: this.params.username || "",
        // phone: this.params.phone || "",
      };
      this.$axiosGet(global.API.getUserList, params).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.dataSource = res.data.results;
          this.pagination.total = res.data.total;
        }
      });
      console.log(global.API.getUserList, "哇");
    },
  },
  mounted() {
    this.isSuperuser = JSON.parse(
      localStorage.getItem("admin.user")
    ).isSuperuser; //判读是不是超级用户
    console.log(this.isSuperuser, "权限");
    this.getUserList();
  },
};
</script>

<style lang="less" scoped>
.user-list {
  height: calc(100vh - 48px);
  box-shadow: none;
  padding: 16px 20px 20px 20px;
  .table-info {
    margin-top: 10px;
  }
  .btn-opt {
    border-radius: 2px;
    font-size: 12px;
    color: #0264c8;
    letter-spacing: 0;
    font-weight: 400;
  }
}
</style>
